import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {useState,useEffect} from 'react'
import { getallbook, updatepayment} from '../../endpoints/apiurl';
import { convertDateFormat } from '../services/timerange';
const BookingTable=()=>{
    const [errormsg,seterrormsg]=useState('')
  const [rows,setrows]=useState([])
  const getrowdata=async()=>{
    try{
      const token = localStorage.getItem('token');
      if (!token) {
          seterrormsg('Token not found');
          return;
      }
      const response=await axios.post(getallbook,null,{
        headers:{
          'authorization':`Bearer ${token}`
        }
      })
       setrows(response.data.data)
      console.log(response.data.data)


    }
    catch(error){
        console.log(error.response?.data?.message || error.response?.data || error.message);

    }
  }
  useEffect(()=>{
    getrowdata()

  },[])
  const handleupdatepayment=async(value)=>{
    const data={id:value}
    try{
      const token = localStorage.getItem('token');
      if (!token) {
          seterrormsg('Token not found');
          return;
      }
      const response=await axios.post(updatepayment,data,{
        headers:{
          'authorization':`Bearer ${token}`
        }
      })
     if(response.data.message==='successful'){
      getrowdata();
      alert('Payment Confirm')
     }

    }catch(error){
      console.log(error.response?.data?.message || error.response?.data || error.message);

    }

  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
            <TableCell align="right">Parent Full Name</TableCell>
            <TableCell align="right">Child's Name</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            <TableCell align="right">Check In</TableCell>
            <TableCell align="right">Time Slots</TableCell>
            <TableCell align="right">Schedule Pattern</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Payment Status</TableCell>
            <TableCell align="right">Reg_Date</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right" component="th" scope="row" className="capitalize">
                {row.parent_fullname}
              </TableCell>
              <TableCell align="right">{row.child_fullname}</TableCell>
              <TableCell align="right">{row.phonenumber}</TableCell>
              <TableCell align="right">{convertDateFormat(row.checkin)}</TableCell>
              <TableCell align="right">{row.timeslots}</TableCell>
              <TableCell align="right">{row.schedulepattern}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="right">{row.payment_status===0?'Not yet Paid':'Paid'}</TableCell>
              <TableCell align="right">{convertDateFormat(row.created_at)}</TableCell>
              <TableCell align="right"><button onClick={()=>handleupdatepayment(row.id)}><i className='fa fa-pencil text-red-500'>Update Payment</i></button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}
export default BookingTable