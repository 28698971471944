import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { changepassword, loginurl, sendotpEmail, verifysendotp } from '../endpoints/apiurl';
import axios from 'axios';
import { useAuth } from '../Authroute/Auth';
import { useHistory, useNavigate } from 'react-router-dom';
import Loader from './preloader/loader';
import { Link } from 'react-router-dom';
const Login=()=>{
    const [Email,setEmail]=useState('') 
    const [password,setpassword]=useState('')
    const [emailerror,setemailerror]=useState(false)
    const [passworderror,setpassworderror]=useState(false)
    const [errormsg,seterrormsg]=useState('')
    const [preloader,setpreloader]=useState(false)
    const [showforpass,setshowforpass]=useState(true)
    const [Emailpass, setEmailpass]=useState('');
    const [showotpinput,setshowotpinput]=useState(false)
    const [otp,setotp]=useState('')
    const [passwordchange,setpasswordchange]=useState('')
    const [passwordconf,setpasswordconf]=useState('')
    const [showpassmodal,setshowpassmodal]=useState(false)
    const { login,isAuthenticated } = useAuth();
    const validateEmail = (email) => {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
      return emailRegex.test(email);
    };
    const handlesubmit = async () => {
      console.log(Email + password);
      
      // Email validation
      if (!Email) {
        setemailerror(true);
        return;
      }
      if (!validateEmail(Email)) {
        setemailerror(true);
        return;
      }
    
      // Password validation
      if (password.length < 6) {
        setpassworderror(true);
        return;
      }
    
      try {
        setpreloader(true);
        const data = { email: Email, password: password };
        const response = await axios.post(loginurl, data);
        
        // Ensure response and response.data are defined before accessing response.data.auth
        if (response && response.data && response.data.auth === true) {
          login(response.data.token);
          

        } 
      } catch (error) {
        console.log(error.response?.data?.message || error.response?.data || error.message);
        seterrormsg(error.response?.data?.message || error.response?.data || error.message);
      } finally {
        setpreloader(false);
      }
    };
    const handleemail=async()=>{
      console.log('ok')
      if(!Emailpass){
        return;
      }
      //verifysendotp
      console.log('ok')
      const data = { email:Emailpass};
      const response = await axios.post(sendotpEmail, data);
      console.log(response.data)
      if(response.data.message==='OTP sent successfully.'){
        setshowotpinput(true)
      }
      else{
        setshowotpinput(false)
      }
     
    }
    const handleotp = async () => {
      try {
          // Input validation
          if (!Emailpass) {
              
              console.error('Email is required.');
              return;
          }
  
          if (!otp) {
             
              console.error('OTP is required.');
              return;
          }
  
          // Construct the payload
          const data = { email: Emailpass, otp: otp };
  
          console.log('Sending OTP verification request with data:', data);
  
          // Send API request
          const response = await axios.post(verifysendotp, data);
  
          console.log('Server response:', response.data);
  
          // Handle success and failure cases
          if (response.data.message === 'OTP verified successfully.') {
              setshowpassmodal(true);
          } else {
              setshowpassmodal(false);
              console.warn('OTP verification failed:', response.data.message);
          }
      } catch (error) {
          console.error('Error in handleotp:', error);
  
          // Handle Axios-specific errors
          if (error.response) {
              // Server responded with a status outside the 2xx range
              console.error('Server responded with an error:', error.response.data);
              alert(`Error: ${error.response.data.message || 'Something went wrong.'}`);
              setshowotpinput(false)
          } else if (error.request) {
              // Request was made but no response received
              console.error('No response received from server:', error.request);
              alert('Error: No response from server. Please check your internet connection.');
          } else {
              // Something else caused the error
              console.error('Error during setup or execution:', error.message);
              alert('Error: An unexpected error occurred. Please try again.');
          }
  
          // Optionally reset states if needed
          setshowpassmodal(false);
      }
  };
  
  const handlechange = async () => {
    try {
        if (!passwordchange) {
            setpassworderror(true);
            alert("Password is required.");
            return;
        }
        if (!passwordconf) {
            setpassworderror(true);
            alert("Password confirmation is required.");
            return;
        }
        if (passwordchange !== passwordconf) {
            setpassworderror(true);
            alert("Passwords do not match.");
            return;
        }

        const data = { email: Email, newpassword: passwordchange };

        // Make the API call
        const response = await axios.post(changepassword, data);

        // Log and handle success response
        console.log(response.data);
        if (response.data.message === 'User updated') {
            alert("Password updated successfully.");
            setshowpassmodal(false);
            setshowforpass(true);

        } else {
            alert("Password update failed. Please try again.");
            setshowforpass(false);
        }
    } catch (error) {
        // Handle errors
        console.error("Error in handlechange:", error);

        // Differentiate between client-side and server-side errors
        if (error.response) {
            // Server responded with a status other than 200
            alert(`Server error: ${error.response.data.message || 'Unknown error occurred.'}`);
        } else if (error.request) {
            // Request was made but no response was received
            alert("No response from the server. Please check your internet connection.");
        } else {
            // Other errors
            alert(`Error: ${error.message}`);
        }
    }
};

    return(
       <>
       {preloader &&<div className='h-screen w-full absolute z-50'><Loader/></div>}
        <div className="w-full h-screen">
            <div className='flex md:flex-row flex-col'>
            <div className='md:h-screen h-72 bg-cover bg-center bg-no-repeat bg-bgside md:w-1/2 w-full py-5'>
                    <div className='fixed w-full md:w-1/2 text-white text-lg h-12 bg-slate-700 opacity-70 px-5 flex items-center'><Link className='flex items-center' to='/'><i className='fa fa-2x fa-home text-creamcolor'></i>&nbsp;Go to Home</Link></div>
                </div>
                 <div className='flex flex-col px-5 md:w-1/2 w-full md:py-10 py-5 relative justify-center items-center'>
                    <div className='fredoka text-lg flex md:justify-end justify-center'>Don’t have an account?&nbsp;<span className='text-bluecolor'><Link to='/register'>Sign up</Link></span></div>
                    {/* start password modal */}
                    {showpassmodal &&<div className='w-full flex flex-col items-center h-96 bg-white absolute z-50'>
                    <div className='md:w-1/2 mt-10 w-5/6'>
                        <div className='text-red-500 text-sm mb-3 text-center'>{errormsg}</div>
                            <TextField
                             label="Password"
                             value={passwordchange}
                             onChange={(event) => {
                                setpasswordchange(event.target.value);
                              }}
                             fullWidth  
                             type='password'
                             error={passworderror}
                             id="outlined-error-helper-text"
                             helperText={passworderror?'Password must not less than 6 character.':''}
                             autoComplete='off'
                            
                            />

                        </div>
                        <div className='md:w-1/2 mt-3 w-5/6'>
                        <div className='text-red-500 text-sm mb-3 text-center'>{errormsg}</div>
                            <TextField
                             label="Confirm Password"
                             value={passwordconf}
                             onChange={(event) => {
                                setpasswordconf(event.target.value);
                              }}
                             fullWidth  
                             type='password'
                             error={passworderror}
                             id="outlined-error-helper-text"
                             helperText={passworderror?'Password must not less than 6 character.':''}
                             autoComplete='off'
                            
                            />

                        </div>
                        <div className='md:w-1/2 mt-5  w-5/6'>
                        <button onClick={handlechange} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Change Password</button>
                        </div>
                    </div>}
                    {/* end password modal */}

                    {/* start login page */}
                    {showforpass &&<div className='flex-1 flex flex-col justify-center items-center md:mt-0 mt-5 w-full'>
                        <div>
                            <div className='text-4xl text-creamcolor potta-one-regular text-center'>Login</div>
                            <div className="text-sm fredoka text-center">Enter your correct details to login to your account</div>
                        </div>
                        <div className='md:w-1/2 mt-10 w-5/6'>
                        <div className='text-red-500 text-sm mb-3 text-center'>{errormsg}</div>
                            <TextField
                             label="Email Address"
                             value={Email}
                             onChange={(event) => {
                                setEmail(event.target.value);
                              }}
                             fullWidth
                             error={emailerror}
                             id="outlined-error-helper-texttwo"
                             helperText={emailerror?'Incorrect entry.':''}
                             autoComplete='off'
                            
                            />

                        </div>
                        <div className='md:w-1/2 mt-3  w-5/6'>
                            <TextField
                             label="Password"
                             value={password}
                             onChange={(event) => {
                                setpassword(event.target.value);
                              }}
                             fullWidth  
                             type='password'
                             error={passworderror}
                             id="outlined-error-helper-text"
                             helperText={passworderror?'Password must not less than 6 character.':''}
                             autoComplete='off'

                            />

                        </div>
                        <div className='md:w-1/2 mt-1  w-5/6'>
                         <button onClick={()=>{setshowforpass(false)}} className='w-full text-right'>Forget Password?</button>
                        </div>
                        <div className='md:w-1/2 mt-5  w-5/6'>
                        <button onClick={handlesubmit} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Login</button>
                        </div>

                    </div>}
                  {/* end loign page */}
                  {/* start email verify */}
                {!showforpass &&<div className='flex flex-col items-center w-full'>
                <div>
                 <div className='text-2xl text-creamcolor potta-one-regular text-center mt-5'>Forgot Password</div>
                 <div className="text-sm fredoka text-center">Check OTP in Email</div>
                </div>
                <div className='md:w-1/2 mt-10 w-5/6'>
                <button onClick={()=>setshowforpass(true)} className='text-sm'>Go back to Login</button>
                <div className='text-red-500 text-sm mb-3 text-center'>{errormsg}</div>
                            <TextField
                             label="Email Address"
                             value={Emailpass}
                             onChange={(event) => {
                              setEmailpass(event.target.value);
                              }}
                             fullWidth
        
                             id="outlined-error-helper-texttwo"
                            
                             autoComplete='off'
                            />

                  </div>
                 {showotpinput && <div className='md:w-1/2 mt-3 w-5/6'>
                            <TextField
                             label="OTP"
                             value={otp}
                             onChange={(event) => {
                              setotp(event.target.value);
                              }}
                             fullWidth
                             type='number'
                          
                             id="outlined-error-helper-texttwo"
                             
                             autoComplete='off'
                            />

                  </div>}
                  {!showotpinput?
                  <div className='md:w-1/2 mt-5  w-5/6'>
                        <button onClick={handleemail} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Submit</button>
                </div>:
                <div className='md:w-1/2 mt-5  w-5/6'>
                        <button onClick={handleotp} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Submit</button>
                </div>              
}

                </div>}
                {/* end email and otp */}


                </div>
              
                

            </div>
           
         </div>
         </>
        

    )
}
export default Login