import imagedashboard from '../images/imagedashboard.png'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useStatStyles } from '@chakra-ui/react';
import { useState } from 'react';
import ParentTable from './parenttable';
import ChildTable from './childtable';
import BookingTable from './bookingtable';
const BookingRecords=()=>{
    const [searchdetail,setsearchdetail]=useState('')
    return(
        <>
        <div className="w-full h-full overflow-y-scroll scrollbar md:overflow-x-hidden">
            <div className="mt-10 w-full px-5">
                <div>
                <div>Booking Records</div>
                <div>
                <TextField
                             label="Search Booking by Date"
                             value={searchdetail}
                             onChange={(event) => {
                                setsearchdetail(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text"
                             helperText={'Enter Name'}
                             InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                 <i  className="fa fa-search"></i>
                                </InputAdornment>
                              ),
                            }}

                            />
                </div>

                </div>
               
                

            </div>
            <div className='px-5'>
            <div className='px-5 mt-5 w-full flex flex-col  h-80 md:h-72 py-5 shadow-md shadow-slate-600 rounded-2xl'>
                <div className='flex items-baseline justify-between'>
                    <div className='text-3xl fredoka'>Booking Table</div>
                    <div className='text-red-500'>See all</div>
                </div>
                <div className='overflow-auto flex-1 scrollbar-track scrollbar md:overflow-x-hidden"'>
                <BookingTable/>

                </div>
              

            </div>
                </div>
               
           

        </div>
        </>
    )
}
export default BookingRecords