import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {useState,useEffect} from 'react'
import { childallrec} from '../../endpoints/apiurl';
const ChildTable=()=>{
    const [errormsg,seterrormsg]=useState('')
  const [rows,setrows]=useState([])
  const getrowdata=async()=>{
    try{
      const token = localStorage.getItem('token');
      if (!token) {
          seterrormsg('Token not found');
          return;
      }
      const response=await axios.post(childallrec,null,{
        headers:{
          'authorization':`Bearer ${token}`
        }
      })
      setrows(response.data.data[0])
      console.log(response.data.data[0])


    }
    catch(error){
        console.log(error.response?.data?.message || error.response?.data || error.message);


    }
  }
  useEffect(()=>{
    getrowdata()

  },[])
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
            <TableCell align="right">Parent Name</TableCell>
            <TableCell align="right">Child Name</TableCell>
            <TableCell align="right">Age</TableCell>
            <TableCell align="right">Age Group</TableCell>
            <TableCell align="right">Date of Birth</TableCell>
            <TableCell align="right">Gender</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right" component="th" scope="row" className="capitalize">
               {row.parent_fullname} 
              </TableCell>
              <TableCell align="right">{row.lastname+' '+row.firstname}</TableCell>
              <TableCell align="right">{row.age}</TableCell>
              <TableCell align="right">{row.agegroup}</TableCell>
              <TableCell align="right">{row.dob}</TableCell>
              <TableCell align="right">{row.gender}</TableCell>
              <TableCell align="right"><i className='fa fa-trash text-red-500'>Delete</i></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}
export default ChildTable