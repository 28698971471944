import imagedashboard from '../images/imagedashboard.png'
import {TextField,FormControl, Select, InputLabel, MenuItem} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { useStatStyles } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ParentTable from './parenttable';
import ChildTable from './childtable';
import BookingTable from './bookingtable';
import {addnumber, addrate, getbankdetails, getnofkidsperhour, getrateall, insertbankdetails } from '../../endpoints/apiurl';
import axios from 'axios'
const Appsetting=()=>{
    const [searchdetail,setsearchdetail]=useState('')
    const [agegroup, setAgeGroup] = useState('');
    const [rate,setrate]=useState('')
    const [noofkids,setnoofkids]=useState('')
    const [ratedata,setratedata]=useState([])
    const [kidnoperhour,setkidnoperhour]=useState('')
    const [accountno,setaccountno]=useState('')
    const [sortcode,setsortcode]=useState('')
    const [bankname,setbankname]=useState('')
    const handleAgeGroupChange = (event) => {
        setAgeGroup(event.target.value);
    };
    const handleaddrate=async()=>{
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
         const data={rate:rate,agegroup:agegroup}
            const response=await axios.post(addrate,data,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })
            if(response.data.message==="data updated"){
                alert(`Rate Added for Age-Group ${agegroup}`)

            }
           
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }
    }
    const handleaddkidperhour=async()=>{
        
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
           
         const data={noofkids:noofkids}
         console.log(data)
            const response=await axios.post(addnumber,data,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })
           
            if(response.data.message==="data updated"){
                alert(`Number of Kids Per Hour Added`)
            }
           
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }
    }
    const fetchrate=async()=>{    
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
            const response=await axios.post(getrateall,null,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })
    
            setratedata(response.data.data)
            
           
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }
    }
    useEffect(()=>{
        fetchrate()
        const pollingInterval = 5000;
        const intervalId = setInterval(fetchrate, pollingInterval);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    },[rate])
    const fetchallperkid=async()=>{    
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
            const response=await axios.post(getnofkidsperhour,null,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })

    
            setkidnoperhour(()=>response.data.data[0].numberofkid)
            
           
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }
    }
    const fetchbankdetail=async()=>{    
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
            
            const response=await axios.post(getbankdetails,null,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })
            setbankname(response.data.data[0].bankname)
            setaccountno(response.data.data[0].accountno)
            setsortcode(response.data.data[0].sortcode)     
           
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }
    }
    useEffect(()=>{
        fetchallperkid()
          // Polling interval (e.g., every 5 seconds)
          const pollingInterval = 5000;
          const intervalId = setInterval(fetchallperkid, pollingInterval);
  
          // Clean up the interval on component unmount
          return () => clearInterval(intervalId);

    },[noofkids])
    useEffect(()=>{
        fetchbankdetail()
           // Polling interval (e.g., every 5 seconds)
           const pollingInterval = 5000;
           const intervalId = setInterval(fetchbankdetail, pollingInterval);
   
           // Clean up the interval on component unmount
           return () => clearInterval(intervalId);

    },[])
    const handleaddpayment=async()=>{
        const token=localStorage.getItem('token')
        if (!token) {
            console.error('Token not found');
            return;
        }
        try{
            const data={bankname:bankname,accountno:accountno,sortcode:sortcode}
            console.log(data)
            const response=await axios.post(insertbankdetails,data,{
                headers:{
                    "authorization":`Bearer ${token}`
                }
            })
           
            if(response.data.message==="data updated"){
                alert(`Bank Details Added`)
            }
        }
        catch(error){
            console.log(error.response?.data?.message || error.response?.data || error.message);

        }

    }
    return(
        <>
        <div className="w-full h-full overflow-y-scroll scrollbar md:overflow-x-hidden">
            <div className="mt-10 w-full px-5">
                <div className='flex justify-around md:flex-row flex-col gap-3 shadow-md shadow-black px-5 py-5 rounded-2xl'>
                    <div className='flex justify-center items-center'>
                        <div>
                    <p className='fredoka text-lg text-center'>Child Per Hour Rate (amount)</p>
                <div className='w-56 h-56 rounded-2xl shadow-sm shadow-black px-3 py-3'>
                <TextField
                             label="Enter Rate"
                             value={rate}
                             onChange={(event) => {
                                setrate(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text" 

                            />
                            <div className='mt-3'>
                            <FormControl fullWidth>
                    <InputLabel id="agegroup-select-label">Age Group</InputLabel>
                    <Select
                        labelId="agegroup-select-label"
                        id="agegroup-select"
                        value={agegroup}
                        label="Age Group"
                        onChange={handleAgeGroupChange}
                    >
                        <MenuItem value="0-2 years">Infants and Toddlers: 0-2 years</MenuItem>
                        <MenuItem value="3-5 years">Preschool Children: 3-5 years</MenuItem>
                        <MenuItem value="6-12 years">Children: 6-12 years</MenuItem>
                    </Select>
                </FormControl>

                            </div>
                            
                            <div className='mt-5'>
                            <button onClick={handleaddrate} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Update</button>
                            </div>

                </div>
                        </div>
                    
                    </div>
                    <div className='flex justify-center items-center'>
                        <div>
                    <p className='fredoka text-lg text-center'>Child Per Hour Rate (amount)</p>
                <div className='w-56 h-56 rounded-2xl shadow-sm shadow-black px-3 py-3'>
                    {ratedata.length>0 &&ratedata.map((item,index)=>(
                    <div key={index} className="mt-5">
                    <div className='flex justify-around'>
                    <div>{item.rate}GBP</div>
                    <div>{item.agegroup}</div>
                    </div>
                    <hr className='border-b border-bluecolor'/>

                    </div>))}

                </div>
                        </div>
                    
                    </div>
                    

                </div>
                <div className='flex justify-around md:flex-row flex-col gap-3 shadow-md shadow-black px-5 py-5 rounded-2xl mt-3'>
                <div className='flex justify-center items-center'>
                        <div>
                    <p className='fredoka text-lg text-center'>Number of Child Per Hour</p>
                <div className='w-56 h-44 rounded-2xl shadow-sm shadow-black px-3 py-3'>
                <TextField
                             label="Enter Number of Child per hour"
                             value={noofkids}
                             onChange={(event) => {
                                setnoofkids(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text" 

                            />
                            <div className='mt-5'>
                            <button onClick={handleaddkidperhour} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Update</button>
                            </div>

                </div>
                        </div>
                    
                    </div>
                    <div className='flex justify-center items-center'>
                        <div>
                <div className='w-56 h-44 rounded-2xl shadow-sm shadow-black px-3 py-3'>
               <div className='flex justify-center items-center h-full'>
                <div className="text-center">
                <div className='text-xl fredoka'> Child</div> 
                <div className='font-bold text-2xl'>{kidnoperhour}</div>

                </div>
            
                </div>

                </div>
                        </div>
                    
                    </div>

                </div>
                <div className='mt-3 flex justify-around md:flex-row flex-col gap-3 shadow-md shadow-black px-5 py-5 rounded-2xl'>
                <div className='flex justify-center items-center'>
                        <div>
                    <p className='fredoka text-lg text-center'>Enter Payment Details</p>
                <div className='w-56 h-auto rounded-2xl shadow-sm shadow-black px-3 py-3'>
                <TextField
                             label="Enter Bank Name"
                             value={bankname}
                             onChange={(event) => {
                                setbankname(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text" 

                            />
                            <div className='mt-3'/>
                            <TextField
                             label="Account Number"
                             value={accountno}
                             onChange={(event) => {
                                setaccountno(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text" 

                            />
                            <div className='mt-3'/>
                            <TextField
                             label="Account sort code"
                             value={sortcode}
                             onChange={(event) => {
                                setsortcode(event.target.value);
                              }}
                             fullWidth  
                             type='text'
                             id="outlined-error-helper-text" 

                            />
                            <div className='mt-5'>
                            <button onClick={handleaddpayment} className="px-8 w-full text-white h-12 active:bg-blue-900 hover:bg-blue-700 bg-bluecolor rounded-xl shadow-md shadow-blue-950">Update</button>
                            </div>

                </div>
                        </div>
                    
                    </div>
                    <div className='flex justify-center items-center'>
                        <div>
                <div className='w-56 h-auto rounded-2xl shadow-sm shadow-black px-3 py-3'>
               <div className='flex justify-center items-center h-full'>
                <div className="text-center">
                <div>
                <div className='text-lg fredoka'>Bank Name</div> 
                <div className='font-bold text-lg'>{bankname}</div>
                <hr className='border-b border-bluecolor'/>

                </div>
                <div className='mt-3'>
                <div className='text-lg fredoka'>Account Number</div> 
                <div className='font-bold text-lg'>{accountno}</div>
                <hr className='border-b border-bluecolor'/>

                </div>
                <div className='mt-3'>
                <div className='text-lg fredoka'>Sort Code</div> 
                <div className='font-bold text-lg'>{sortcode}</div>
                <hr className='border-b border-bluecolor'/>

                </div>
               


                </div>
            
                </div>

                </div>
                        </div>
                    
                    </div>

                </div>
                
                
                </div>
        </div>
        </>
    )
}
export default Appsetting