import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthAdmin } from './AuthAdmin';

const Protectedrouteadmin = ({ children }) => {
  const { isAuthenticated } = useAuthAdmin();
  console.log(isAuthenticated)
  return isAuthenticated ? children : <Navigate to="/adminlogin" />;
};

export default Protectedrouteadmin;
