import axios from "axios"
import { getinvoiceid, sendemail } from "../../endpoints/apiurl"
import { useEffect,useState } from "react"
import { convertDateFormat, daysextend } from "../services/timerange"

const Paymentpage=(props)=>{
    const [startdate,setstartdate]=useState('')
    const [enddate,setenddate]=useState('')
    const [errormsg,seterrormsg]=useState('')
    const [totalmaount,settotalamount]=useState('')
    const [daysno,setdaysno]=useState('')
    const SendEmail=async()=>{
        try{
            const token = localStorage.getItem('token');
            if (!token) {
                seterrormsg('Token not found');
                return;
            }
            const data={invoiceid:props.invoiceid,startdate,enddate,days:daysno}
            console.log(data)
            const response=await axios.post(sendemail,data,{
              headers:{
                'authorization':`Bearer ${token}`
              }
            })
         if(response.status===200){
            props.action()
            alert ('check your Email')
         }
          
      
      
          }
          catch(error){
              console.log(error.response?.data?.message || error.response?.data || error.message);
      
      
          }

    }
    const handleaction=()=>{
        props.action()
    }
    const fetchdetails=async()=>{
        try{
            const token = localStorage.getItem('token');
            if (!token) {
                seterrormsg('Token not found');
                return;
            }
            const data={id:props.invoiceid}
            const response=await axios.post(getinvoiceid,data,{
              headers:{
                'authorization':`Bearer ${token}`
              }
            })
            const datadetail=response.data.data[0]
            setstartdate(convertDateFormat(datadetail.created_at))
            const days=datadetail.schedulepattern
            setdaysno(days)
            console.log(days)
            setenddate(daysextend(days,datadetail.created_at))
            settotalamount(datadetail.amount)
          
      
      
          }
          catch(error){
              console.log(error.response?.data?.message || error.response?.data || error.message);
      
      
          }
        
    }
    useEffect(()=>{
        fetchdetails()
    },[])
    return(
        <div className="w-full flex justify-center">
            <div className='w-72 md:w-105  bg-slate-100 rounded-2xl px-3 py-5'>
                <div>You are Making Payment for {daysno}days</div>
                <div>From {startdate}</div>
                <div>End {enddate}</div>
                <div>Total Amount:{totalmaount} GBP</div>
                <div className="text-center font-bold fredoka">Check Your Email for Payment details</div>
                <div className="flex justify-center"><div onClick={SendEmail} className="bg-bluecolor rounded-2xl text-white w-8 h-8 items-center flex  justify-center cursor-pointer">Ok</div></div>

                </div>
                </div>
    )
}
export default Paymentpage