import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import {useState,useEffect} from 'react'
import { getalldata } from '../../endpoints/apiurl';
const ParentTable=()=>{
    const [errormsg,seterrormsg]=useState('')
  const [rows,setrows]=useState([])
  const getrowdata=async()=>{
    try{
      const token = localStorage.getItem('token');
      if (!token) {
          seterrormsg('Token not found');
          return;
      }
      const response=await axios.post(getalldata,null,{
        headers:{
          'authorization':`Bearer ${token}`
        }
      })
      setrows(response.data.data[0])
      console.log(response.data.data)


    }
    catch(error){

    }
  }
  useEffect(()=>{
    getrowdata()

  },[])
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell>ID</TableCell>
            <TableCell align="right">Full Name</TableCell>
            <TableCell align="right">Phone Number</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">Occupation</TableCell>
            <TableCell align="right">No of Children</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell align="right">{row.id}</TableCell>
              <TableCell align="right" component="th" scope="row" className="capitalize">
                {row.lastname+' '+row.firstname}
              </TableCell>
              <TableCell align="right">{row.phonenumber||'null'}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.address}</TableCell>
              <TableCell align="right">{row.occupation}</TableCell>
              <TableCell align="right">{row.number_of_children}</TableCell>
              <TableCell align="right"><i className='fa fa-trash text-red-500'>Delete</i></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}
export default ParentTable