// const BaseURi='http://localhost:3300/api/v1'
const BaseURi='https://appychild.uk/backend/api/v1'
//  const BaseURi='https://happychildbackend.onrender.com/api/v1'
export const loginurl=`${BaseURi}/login`
export const register=`${BaseURi}/register`
export const update=`${BaseURi}/update`
export const emailstatus=`${BaseURi}/emailstatus`
export const sendotp=`${BaseURi}/sendotp`
export const verifyotp=`${BaseURi}/verifyotp`
export const userdetails=`${BaseURi}/userdetails`
export const registerchild=`${BaseURi}/childadd`
export const getchilddata=`${BaseURi}/childrecord`
export const getchilddelete=`${BaseURi}/childremove`
export const getchildudate=`${BaseURi}/childupdate`
export const gethour=`${BaseURi}/getavailablehour`
export const book=`${BaseURi}/insertbook`
export const getinvoiceid=`${BaseURi}/getbook`
export const getbookbyparentid=`${BaseURi}/getbookbyparentid`
export const loginadmin=`${BaseURi}/adminlogin`
export const regadmin=`${BaseURi}/registeradmin`
export const getalldata=`${BaseURi}/getalldata`
export const childallrec=`${BaseURi}/childallrec`
export const getallbook=`${BaseURi}/getallbook`
export const getnofkidsperhour=`${BaseURi}/getnofkidsperhour`
export const addnumber=`${BaseURi}/addnumberofkidsperhour`
export const addrate=`${BaseURi}/addrate`
export const getrateall=`${BaseURi}/getrateall`
export const getratebygroup=`${BaseURi}/getratebygroup`
export const insertbankdetails=`${BaseURi}/insertbankdetails`
export const getbankdetails=`${BaseURi}/getbankdetails`
export const sendemail=`${BaseURi}/sendemail`
export const contactus=`${BaseURi}/contactcenter`
export const updatepayment=`${BaseURi}/updatepayment`
export const sendotpEmail=`${BaseURi}/sendotpEmail`
export const verifysendotp=`${BaseURi}/verifysendotp`
export const changepassword=`${BaseURi}/changepassword`



